import React from 'react';
import { css } from 'emotion';
import '../../assets/fonts/fonts.css';

/* -------------------------------------------------------
    * Carcard Component starts here
----------------------------------------------------------*/

/* global tw */
const styles = {
    header: css`
        ${tw`w-full flex justify-between items-center`};
        // height: 5.9vh;
        height: 9.5488257108vh;
        @media (min-width: 992px){
            height: 9.5488257108vh;
        } 
    `,
    listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll overflow-x-hidden scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
        -webkit-overflow-scrolling: touch;

    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-2 lg:px-6 lg:px-0 -mx-2 mx-auto scrolling-touch`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    listingRow: css`
        ${tw`flex flex-wrap w-full items-center h-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            // max-width: 75rem;
        }
    `,
    itineraryBlob: css`
        ${tw`w-full lg:w-1/2 py-0 px-2 lg:py-4 `}
        &:hover{
            cursor: pointer;
            background: rgba(240,242,245, 0.4);
            border-radius: 8.09px;
            color: purple;
        }

    `,
    fromToLocations: css`
        ${tw`lg:text-2xl text-normal font-bold opacity-70`}
        color: #222222;
        letter-spacing: -0.73px;
    `,
    blackOverlay: css`
        ${tw`fixed z-200 h-screen w-screen overflow-hidden pin-t`};
        background: rgba(0,0,0,0.6);
    `,
    choiceFilterButton: css`
        ${tw`flex items-center px-4 py-2 fixed z-50 pin-b pin-x bg-white mx-auto mb-2 md:mb-4 lg:mb-8 justify-between`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 95vw;
        height: 3.5rem;
        @media (min-width: 992px){
            width: 33vw;
            height: 5rem; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            // background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
            // -webkit-background-clip: text;
            -webkit-text-fill-color: purple;
            color: purple;
            ${tw`font-bold uppercase`};
            font-size: 0.8rem;
            @media(min-width: 992px){
                font-size: 1rem;
            }
            span{
                ${tw`block text-sm font-medium normalcase opacity-60`};
                color: black;
                -webkit-text-fill-color: black;
                font-size: 0.6rem;
                 @media(min-width: 992px){
                    font-size: 0.7rem;
                }
            }
        }
    `,
    filterButton: css`
        ${tw`flex items-center px-4 py-2 fixed z-50 pin-b pin-x bg-white mx-auto mb-2 md:mb-4 lg:mb-8 justify-center align-center`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        transition: all 0.25s ease-in-out;
        width: 15vw;
        height: 15vw;
        @media (min-width: 992px){
            width: 5vw;
            height: 5vw; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            // background: -webkit-linear-gradient(#AA0BCA, #7D0EDE);
            // -webkit-background-clip: text;
            -webkit-text-fill-color: purple;
            color: purple;
            ${tw`font-bold uppercase`};
            font-size: 0.8rem;
            @media(min-width: 992px){
                font-size: 1rem;
            }
            span{
                ${tw`block text-sm font-medium normalcase opacity-60`};
                color: black;
                -webkit-text-fill-color: black;
                font-size: 0.6rem;
                 @media(min-width: 992px){
                    font-size: 0.7rem;
                }
            }
        }
    `,
    filterButtons: css`
        ${tw`flex text-center lg:text-left lg:px-8 mx-2 px-6 py-4 -mx-2 rounded w-1/2 bg-grey-light items-center cursor-pointer`};
    `,
    bookingForm: css`
        ${tw`items-center flex flex-wrap bg-white mt-12 relative w-full overflow-hidden mx-4`};
        border-radius: 8.09px;
        box-shadow: 0 6px 24px rgba(0,0,0,0.2);
        height: max-content;
        // max-height: initial;
        @media (min-width: 992px){
            height: 38.195302843vh;
        }

    `,
    datePicker: css`
        ${tw`list-reset`};

        li{
            ${tw`my-4 py-1 lg:py-2 px-1 lg:px-4 cursor-pointer`};
            &:hover{
                ${tw`bg-grey-light`}
            }
        }
        li.active{
            ${tw`bg-grey-dark text-white`};
        }

    `,
    autocompleteSuggestions: css`
        ${tw`list-reset w-full`};
        li{
            ${tw`py-2 px-1 my-1 text-grey-darker font-sans lg:text-lg hover:bg-grey-light`};

        }
    `,
    dateTimeScrollWrapper: css`
        ${tw`w-gr1/3 lg:text-2xl mr-6 lg:px-2 py-2 overflow-y-scroll`}; 
        height:30vh;
        -webkit-overflow-scrolling: touch;
        -webkit-mask-image: -webkit-gradient(linear, left 90%,left bottom, 0%(rgba(0,0,0,1)), 25%(rgba(0,0,0,0)),75%(rgba(0,0,0,0)), 100%(rgba(0,0,0,1)))
    `,
    itineraryLine: css`
        ${tw`w-full flex flex-wrap justify-between py-1 lg:py-1 px-6`}
        // border-bottom: 1px solid #F0F2F5;
    `,

}

const CarCard = (props) => {
    let car = props.car;
    let couponApplied, originalPrice, finalPrice, type;
    // console.log(props.car);
    if(typeof props.car === `undefined`){
        return null;
    }
    if (props.car.hasOwnProperty('vendors') && props.car.category === 'SELF DRIVE') {
        type='self';
        if (props.selectedVendor?.couponValue <= 0){
            couponApplied = false;
        }
        else{
            couponApplied = true;
            }    
            originalPrice = Math.round(props.selectedVendor.base_fare).toLocaleString();
            finalPrice = Math.round(props.selectedVendor.discountedValue).toLocaleString();
            let selfVendors = Object.assign([],props.car.vendors);
            // console.log(selfVendors);
            Object.assign(car,{'vendors':selfVendors});
            // console.log(car);
    }
    else {
        type='';
        if (props.selectedVendor?.couponValue <= 0 ){
            couponApplied = false;
        }
        else{
            couponApplied = true;
        }
            originalPrice = Math.round(props.selectedVendor.ride_amt).toLocaleString();
            finalPrice = Math.round(props.selectedVendor.discountedValue).toLocaleString();
    }
    // }
    // else {
    //     if (props.selectedVendor?.couponValue <= 0) {
    //         couponApplied = false;
    //     }
    //     else {
    //         couponApplied = true;
    //     }
    //     originalPrice = Math.round(amt).toLocaleString();
    //     finalPrice = Math.round(props.selectedVendor?.discountedValue).toLocaleString();
    // }
    return (
        <div onClick={props.onClick} className={cardStyles.CarCard}>
            <div className={css`${tw`block w-full`}`}>
                <img className={cardStyles.carImage} src={props.car.sub_category_url} />
                <h3 className={cardStyles.carName}>{props?.selectedVendor?.account_name !== "Swing Partner" ? props.car.sub_category : props?.selectedVendor?.vehicle_name}</h3>
                <div className={css`${tw`flex w-full mx-auto`}`}>
                    { /* <p className={cardStyles.carSpecs}>{props.car.no_of_seats} Seats</p> */}
                    <div className={css`${tw`flex flex-row`}`}>
                        <img src={require('../SvgComponents/seats.svg')} />
                        <p className={cardStyles.carSpecs}>{props.car.no_of_seats}</p>
                        {props.car.category === "SELF DRIVE" && <img src={require('../SvgComponents/gear-type.svg')} />}
                        {props.car.category === "SELF DRIVE" && <p className={cardStyles.carSpecs}>{props.selectedVendor?.gear_type}</p>}
                        {props.car.category === "SELF DRIVE" && <p className={cardStyles.carSpecs}>{props.selectedVendor?.fuel_type}</p>}
                        <p className={cardStyles.carSpecs}>A/C</p>
                    </div>
                </div>
            </div>


            <div className={css`${tw`w-full items-center mt-5 -ml-2 cursor-pointer`}`}>
                <div className={css`${tw`w-full flex`}`}>
                    <div className={css`${tw`w-1/2 h-auto px-4 border-solid border-2 border-borderColor rounded-lg mx-2`}`}>

                        <div className={css`${tw`flex flex-row items-center justify-evenly pt-2`}`}>

                            { /* <p className={css`${tw`font-light line-through opacity-50 text-sm tracking-tight`}`}>
                                    &#8377; {originalPrice} 
                                </p> */}
                            <p className={css`${tw`font-bold text-xl tracking-tight`}`}>
                                &#8377;{originalPrice}
                            </p>
                            {couponApplied &&
                                <div className={css`${tw`flex items-center ml-2`}`}>
                                    {/* <img className={css`${tw`inline-flex`}`} src={require('../SvgComponents/couponLabelSvg.svg')} alt="Zyppys" /> */}
                                    { /* <p className={cardStyles.couponText}>Best Coupon Applied</p> */}
                                </div>
                            }
                        </div>

                        {props.selectedVendor?.hasOwnProperty('package_kms') && <p className={css`${tw`font-light opacity-50 text-sm tracking-tight uppercase`}`}>
                            For {props.type !== 'self' ?
                                props.selectedVendor?.package_kms.toLocaleString() + ' Kms'

                                : props.selectedVendor.hasOwnProperty('package_kms') ?
                                    ((typeof props.selectedVendor?.package_kms === 'string' ? (props.selectedVendor?.package_kms.includes('kms') || props.selectedVendor?.package_kms.includes('Kms') ? props.selectedVendor?.package_kms : props.selectedVendor?.package_kms + ' Kms')
                                        : props.selectedVendor?.package_kms.toLocaleString()(typeof props.selectedVendor?.package_kms === 'string' && (props.selectedVendor?.package_kms.includes('kms') || props.selectedVendor?.package_kms.includes('Kms')) ? '' : 'Kms')))
                                    : props.myChoizeKms
                            }
                        </p>}
                    </div>
                    {/* <div className={css`${tw`w-1/2 self-center font-medium opacity-50 text-sm tracking-tight ml-2 mt-2`}`}>&#8377;{(500).toLocaleString()} off on App</div> */}
                    
                    <div
  className={css`
    ${tw`w-1/2 self-center font-medium opacity-50 text-sm tracking-tight ml-2 mt-2`}
  `}
>
  {props.selectedVendor?.security_deposit &&
  props.selectedVendor.security_deposit !== "N/A" &&
  parseInt(props.selectedVendor.security_deposit) > 0 ? (
    <>
      Refundable Deposit{" "}
      <span
        className={css`
          ${tw`font-bold`}
        `}
      >
        ₹{props.selectedVendor.security_deposit.toLocaleString()}
      </span>
    </>
  ) : (
    <>
      Refundable Deposit{" "}
       <span className={css`
        ${tw`font-bold`}
      `}>N/A</span>
    </>
  )}
</div>


                </div>
                {props.car.category === "SELF DRIVE" && <p className={css`${tw`font-medium opacity-50 text-sm tracking-tight mt-2 ml-4`}`}>Fuel: {props.selectedVendor?.measure_cd.includes('without') ? 'Not Included' : 'Included'}</p>}
                <div className={css`${tw`w-full h-px bg-grey mt-2`}`} />

            </div>
            <div className={css`${tw`mt-5`}`}>
                <p className={cardStyles.providedText}>Service Provided By</p>
                <div className={css`${tw`flex flex-row items-center justify-between `}`}>

                    <div className={css`${tw`flex flex-row items-center justify-between text-left mt-1`}`}>
                        {/* <div className={css`${tw`h-8 mt-2`}`}
                            style={{
                                width: '5rem',
                                backgroundImage: `url(${props.type !== 'self' ? props.selectedVendor?.image_url : props.selectedVendor?.image_url})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                            }}>
                        </div> */}
                        <div className={css`${tw`h-8 mt-2 flex items-center justify-center text-center font-medium text-sm`}`}
                             style={{
                             width: '6rem',
                             whiteSpace: 'nowrap',  
                             overflow: 'hidden',    
                             textOverflow: 'ellipsis'
                                    }}>
                        {props.type !== 'self' ? props.selectedVendor?.account_name : props.selectedVendor?.account_name}
                        </div>
                        {/* {props.selectedVendor?.vehicle_owner_business_name && <p style={{ textTransform: "capitalize" }}>{props.selectedVendor?.vehicle_owner_business_name !== "" ? props.selectedVendor?.vehicle_owner_business_name : props.selectedVendor?.vehicle_owner_name}</p>} */}
                        {/* {props.selectedVendor?.vehicle_owner_business_name && <p style={{ textTransform: "capitalize" }}>{props.selectedVendor?.vehicle_owner_business_name}</p>} */}
                        
                        <div className={css`${tw`h-8 w-px bg-grey mx-2`}`} />

                        <p className={css`${tw`w-1/3 -mt-1 text-sm text-black font-medium`}; left: 10%`}>
                            <span className={css`${tw`inline-flex`}`}><img src={require('../SvgComponents/starLabelSvg.svg')} alt="rating" /></span>
                            {props.type !== 'self' ? props.selectedVendor?.rating : props.selectedVendor?.rating}
                        </p>
                    </div>

                    {/* {props.car.vendors.length > 1 && <div className={cardStyles.lastContainer}>

                        <p className={cardStyles.serviceText}>
                            More Choices
                            </p>
                        <img src={require('../SvgComponents/drop-arrow.svg')} alt="arrow" />
                    </div>} */}

                </div>
            </div>
            {/* <Link paintDrip to="reviewPage" hex="#AA0BCA" duration={2} className={css`${tw`no-underline absolute pin-t pin-l z-10 h-full w-full`}`}></Link> */}
            { /* <AniLink paintDrip to="/reviewPage/" hex="#AA0BCA" duration={2} className={css`${tw`no-underline absolute pin-t pin-l z-10 h-full w-full`}`}></AniLink> */}
        </div>

    )
};

const cardStyles = {
    CarCard: css`
        ${tw`relative flex flex-wrap items-center z-0 my-6 lg:mx-4 px-6 py-6`};
        min-height: 22rem;
        height: auto;
        border-radius: 16px;
        background: #f8f8fb;
        transition: all 0.25s ease-in-out;
        width: 100%;
        box-sizing: border-box;
        @media (min-width: 992px){
            min-height: 22rem;
            height: auto;
            width: 30%;
            &:hover{
                cursor: pointer;
                background: white;
                transform: translate3d(0,0,5px) scale(1.01);
                box-shadow: 0 15px 30px -10px rgba(0,0,0,0.2);
            }
        }
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
        }

        ${'' /* to remove the lnk colors from the cards and all 'a' links */}
        a:-webkit-any-link {
            color: inherit !important;
        }

    `,
    carImage: css`
        height: 8.5rem;
        @media(min-width: 992px){
            // transform: scale(1.1);
        }
    `,
    carName: css`
        ${tw`ml-0 mb-0`};
        opacity: 0.9;
        color: #222222;
        letter-spacing: -0.73px;
        font-size: 18px;
        @media(min-width: 992px){
            font-size: 20px;
        
        }
    `,
    reviewText: css`
        ${tw`block text-xl`};
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;
        margin-left:5px;
        vertical-align: middle;
        ${'' /* &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        } */}
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    carSpecs: css`
        opacity: 0.5;
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;
        margin-left:5px;
        vertical-align: middle;
        ${'' /* &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        } */}
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    couponText: css`
        ${tw`ml-2 my-2 opacity-40`};
        opacity: 0.4; 
        font-family: 'proxima-nova', ProximaNova-Regular; 
        letter-spacing: -0.23px; 
        font-size: 0.6rem;
        @media (min-width: 992px){
            font-size: 0.6rem;
        }
    
    `,
    serviceText: css`
        ${tw`w-10 text-right`};
        font-family: 'proxima-nova', ProximaNova-Regular; 
        font-size: 0.6rem;
        font-style: normal;
        ${'' /* font-weight: 500; */}
        font-size: 12px;
        line-height: 13px;
        letter-spacing: -0.4px;
        color: #414242;
        mix-blend-mode: normal;
        opacity: 0.5;
        margin-right:1rem;
        ${'' /* margin-left:4rem; */}
    `,
    providedText: css`
    font-family: 'proxima-nova', ProximaNova-Regular; 
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.4px;

    color: #414242;

    mix-blend-mode: normal;
    opacity: 0.5;
    `,
    lastContainer: css`
    ${tw`flex flex-row items-center justify-between text-right`};
    ${'' /* left:50%; */}
    position: absolute;
    right:10%;
    cursor:pointer;

    `,
}

export default CarCard;

